#ReviewsRoot {
  .customerReviewsContainer {
    display: flex;
    flex-direction: column;
    gap: 2%;
    row-gap: 30px;

    @include lg {
      flex-direction: row;
    }

    .review {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 10px;
      border: 1px solid $pale-grey;
      padding: 20px;
      background-color: $white;
      border-radius: 10px;

      .header {
        .author {
          margin-top: 10px;
          font-weight: bold;
        }
      }

      p {
        margin: 0;
        text-align: left;
      }
    }
  }
}

